import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import moment from 'moment-timezone';
import { Sparklines, SparklinesLine, SparklinesReferenceLine } from 'react-sparklines';

function Dashboard({state}) {
	const {weatherData, protoStats, priceData, solarData, solarHistory, sensorData, gridData} = state.data;

	const mean = (x, y) => (x+y)/2;

	return (
		<div className='dashboard'>
			<div className='dashboard-stats'>
				{weatherData ?
					<>
						<p className='small'>{weatherData.weather[0].description}</p>
						<p>{weatherData.main.temp.toFixed(1)}°, {weatherData.main.feels_like.toFixed(1)}°, {weatherData.main.humidity}%</p>
					</>
				:
					<p>Loading weather</p>
				}

				{protoStats ?
					<p>
						{protoStats.member_count}, {protoStats.card_scans}, {protoStats.minecraft_players.length}
					</p>
				:
					<p>Loading Protopace</p>
				}

				{priceData ?
					<>
						<p>{'$' + Math.round(priceData.data.btc_cad)}, {priceData?.data?.volume?.toFixed(1)}</p>
					</>
				:
					<p>Loading prices</p>
				}

				{solarHistory && solarData && solarData.timestamp ?
					<>
						<p>
							{solarData.actual_total}W, {parseInt(solarData.actual_total / 5985 * 100)}%{' '}
							<div className='sparkline'>
								<Sparklines data={solarHistory.map(x => x.actual_total)} height={50}>
									<SparklinesLine color='white' />
								</Sparklines>
							</div>
						</p>
						<p>{solarData.today_energy}kWh, {solarData.timestamp.substring(11, 16)}</p>
					</>
				:
					<p>Loading solar</p>
				}

				{sensorData ?
					<>
						<p>O: {sensorData.temperature.Outside[0].temperature_C.toFixed(1)}° B: {sensorData.temperature.Bedroom[0].temperature_C.toFixed(1)}°</p>
						<p>N: {sensorData.temperature.Nook[0].temperature_C.toFixed(1)}° T: {sensorData.temperature.Basement[0].temperature_C.toFixed(1)}°</p>
						<p>P10: {sensorData.air['Living Room'][0].pm10.toFixed(1)} P25: {sensorData.air['Living Room'][0].pm25.toFixed(1)}</p>
						<p>C: {sensorData.air['Living Room'][0].co2.toFixed(1)} V: {sensorData.air['Living Room'][0].voc_idx}</p>
					</>
				:
					<p>Loading sensors</p>
				}
			</div>

		</div>
	);
}

export default Dashboard;
